<template>
  <section>
    <section class="search-container">
      <a-form layout="inline" class="m-t-14">
        <a-form-item label="单位名称">
          <a-input
            class="search-input"
            v-model="searchInfo.companyName"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="人员名称">
          <a-input
            class="search-input"
            v-model="searchInfo.personnelName"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" @click="search"
            ><a-icon type="search" />查询</a-button
          >
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
          <router-link
            class="item"
            to="/pcms/labour/blacklist/add"
            style="margin-left: 10px"
          >
            <a-button type="primary">添加人员</a-button>
          </router-link>
        </a-form-item>
      </a-form>
    </section>
    <section class="list-table-container m-t-18" v-if="listMap.length">
      <a-table
        size="small"
        :columns="columns"
        :data-source="listMap"
        :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum"
      >
        <span
          slot="status"
          slot-scope="text"
          :class="text == 0 ? 'list-status-in' : 'list-status-out'"
          >{{ text == 0 ? "生效中" : "已失效" }}</span
        >
        <a slot="action" slot-scope="text, list">
          <router-link
            :to="{
              path: '/pcms/labour/blacklist/details',
              query: { id: list.id },
            }"
            >查看</router-link
          >
          <router-link
            :to="{ path: '/pcms/labour/blacklist/activation', query: { id: list.id } }"
            style="margin-left: 10px" v-if="list.flag==1"
            >激活</router-link
          >
          <router-link
            :to="{
              path: '/pcms/labour/blacklist/relieve',
              query: { id: list.id },
            }"
            style="margin-left: 10px" v-else
            >解除限制</router-link
          >
          <!-- <a-popconfirm title="确定删除该车辆类型?" @confirm="deleteItem(list.id)">删除</a-popconfirm> -->
        </a>
      </a-table>
    </section>
    <empty v-else :height="430" />
    <!-- <section class="pagination-container">
            <a-pagination size="default" :pageSize="pageSize" :current="pageNum" :total="total"  hide-on-single-page @change="changePageNum" />
        </section> -->
  </section>
</template>

<script>
const columns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "单位名称",
    dataIndex: "companyName",
    align: "center",
    key: "companyName",
  },
  {
    title: "姓名",
    dataIndex: "personnelName",
    align: "center",
    key: "personnelName",
  },
  { title: "身份证号", dataIndex: "idCard", align: "center", key: "idCard" },
  {
    title: "添加时间",
    dataIndex: "createTime",
    align: "center",
    key: "createTime",
  },
  {
    title: "有效期限",
    dataIndex: "validityDate",
    align: "center",
    key: "validityDate",
  },
  // {title:'类别',dataIndex:'doorwayName',align:'center',key:'doorwayName'},
  // {title:'来源',dataIndex:'doorwayName',align:'center',key:'doorwayName'},
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  { title: "操作", align: "center", scopedSlots: { customRender: "action" } },
];
import empty from "@/components/empty";
import moment from "moment";
import { exprotFile } from "@/until/unit";
export default {
  data() {
    return {
      columns,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      listMap: [],
      searchInfo: {},
    };
  },
  components: { empty },
  created() {
    this.queryBlackList();
  },
  methods: {
    exprotFile,
    //查询人员黑名单列表
    queryBlackList() {
      let params = { ...this.searchInfo };
      params.current = this.pageNum;
      params.size = this.pageSize;
      this.$api.queryBlackList(params).then((res) => {
        if (res.code === 200) {
          this.listMap=[];
          this.listMap = (res.data && res.data.records) || [];
          this.listMap.forEach((item, ind) => {
            item.key = (this.pageNum-1)*this.pageSize + (ind+1)
            item.createTime = moment(item.createTime).format("YYYY-MM-DD");
            if (item.validityDate != -1) {
              item.validityDate = item.createTime + "~" + item.validityDate;
            } else {
              item.validityDate = "永久";
            }
          });
          this.total = (res.data && res.data.total) || 0;
        }
      });
    },
    reset() {
      this.searchInfo = {};
      this.pageNum = 1;
      this.queryBlackList();
    },
    search() {
      this.pageNum = 1;
      this.queryBlackList();
    },
    changePageNum(p) {
      this.pageNum = p.current;
      this.queryBlackList();
    },
  },
};
</script>
<style lang="scss" scoped>
.list-status-in {
  color: #52c41a;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #52c41a;
  }
}
.list-status-out {
  color: #e25745;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #e25745;
  }
}
</style>