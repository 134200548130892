var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('section',{staticClass:"search-container"},[_c('a-form',{staticClass:"m-t-14",attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"单位名称"}},[_c('a-input',{staticClass:"search-input",attrs:{"placeholder":"请输入"},model:{value:(_vm.searchInfo.companyName),callback:function ($$v) {_vm.$set(_vm.searchInfo, "companyName", $$v)},expression:"searchInfo.companyName"}})],1),_c('a-form-item',{attrs:{"label":"人员名称"}},[_c('a-input',{staticClass:"search-input",attrs:{"placeholder":"请输入"},model:{value:(_vm.searchInfo.personnelName),callback:function ($$v) {_vm.$set(_vm.searchInfo, "personnelName", $$v)},expression:"searchInfo.personnelName"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"m-r-8",attrs:{"type":"primary"},on:{"click":_vm.search}},[_c('a-icon',{attrs:{"type":"search"}}),_vm._v("查询")],1),_c('a-button',{on:{"click":_vm.reset}},[_c('a-icon',{attrs:{"type":"reload"}}),_vm._v("重置")],1),_c('router-link',{staticClass:"item",staticStyle:{"margin-left":"10px"},attrs:{"to":"/pcms/labour/blacklist/add"}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("添加人员")])],1)],1)],1)],1),(_vm.listMap.length)?_c('section',{staticClass:"list-table-container m-t-18"},[_c('a-table',{attrs:{"size":"small","columns":_vm.columns,"data-source":_vm.listMap,"pagination":{total: _vm.total,pageSize: _vm.pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}},on:{"change":_vm.changePageNum},scopedSlots:_vm._u([{key:"status",fn:function(text){return _c('span',{class:text == 0 ? 'list-status-in' : 'list-status-out'},[_vm._v(_vm._s(text == 0 ? "生效中" : "已失效"))])}},{key:"action",fn:function(text, list){return _c('a',{},[_c('router-link',{attrs:{"to":{
            path: '/pcms/labour/blacklist/details',
            query: { id: list.id },
          }}},[_vm._v("查看")]),(list.flag==1)?_c('router-link',{staticStyle:{"margin-left":"10px"},attrs:{"to":{ path: '/pcms/labour/blacklist/activation', query: { id: list.id } }}},[_vm._v("激活")]):_c('router-link',{staticStyle:{"margin-left":"10px"},attrs:{"to":{
            path: '/pcms/labour/blacklist/relieve',
            query: { id: list.id },
          }}},[_vm._v("解除限制")])],1)}}],null,false,2847821079)})],1):_c('empty',{attrs:{"height":430}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }